<template>
  <div>
    <v-navigation-drawer
      v-model="show"
      app
      temporary
      clipped
      hide-overlay
      right
      width="600"
      class="pt-16"
    >
      <v-toolbar>
        <v-btn
          @click="$emit('click:close')"
        >
          Luk
        </v-btn>
      </v-toolbar>
      <ActivityLog
        title="Beskeder"
        :entries="messages"
        :relationId="this.spec.id"
        relationKind="specification"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import ActivityLog from '../ActivityLog.vue';

export default {
  name: 'SpecificationMessages',
  components: {
    ActivityLog
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    spec: {
      type: Object,
      required: true
    },
    messages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>