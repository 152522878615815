<template>
  <div>
    <v-navigation-drawer
      permanent
      left
      app
      clipped
      width="300"
    >
      <v-list dense nav open-strategy="multiple">
        <v-list-item :to="relationRoute" exact>
          <v-list-item-avatar>
            <v-icon> mdi-arrow-left </v-icon>
          </v-list-item-avatar>

          <v-list-item-title>
            {{ spec?.relationTitle ?? 'Tilbage' }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item :to="specBaseRoute" exact>
          <v-list-item-avatar>
            <v-icon> mdi-home-outline </v-icon>
          </v-list-item-avatar>

          <v-list-item-title>
            Oversigt
          </v-list-item-title>
        </v-list-item>

        <v-list-item :to="specBaseRoute + '/approval'" exact>
          <v-list-item-avatar>
            <v-icon> mdi-script-text-outline </v-icon>
          </v-list-item-avatar>

          <v-list-item-title>
            Leveranceaftale
          </v-list-item-title>
        </v-list-item>

        <v-list-item :to="specBaseRoute + '/resources'" exact>
          <v-list-item-avatar>
            <v-icon> mdi-finance </v-icon>
          </v-list-item-avatar>

          <v-list-item-title>
            Resurseudgifter
          </v-list-item-title>
        </v-list-item>

        <v-list-item :to="specBaseRoute + '/travel'" exact>
          <v-list-item-avatar>
            <v-icon> mdi-airplane </v-icon>
          </v-list-item-avatar>

          <v-list-item-title>
            Rejseudgifter
          </v-list-item-title>
        </v-list-item>

        <v-list-group
          v-for="(group) in spec.groups"
          :key="group.name"
          :color="$vuetify.breakpoint.mobile ? 'white' : ''"
          @click="$router.push(`${specBaseRoute}/group/${group.number}`)"
        >
          <template #activator>
            <v-list-item-avatar>
              <v-icon> mdi-folder-outline </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ group.number }} {{ group.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(section) in group.sections"
            :key="section.title"
            class="pl-6"
            :to="`${specBaseRoute}/group/${group.number}/section/${section.number}`"
          >
            <v-list-item-avatar>
              <v-icon> mdi-file-document-outline </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ group.number }}.{{ section.number }} {{ section.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="text-caption">
                {{ section.quantity }}
              </div>
            </v-list-item-action>
          </v-list-item>

          <SpecificationSectionCreate
            :group="group"
            :locked="locked"
            :spec="spec"
          />
        </v-list-group>

        <v-divider></v-divider>

        <SpecificationSectionGroupCreate
          :locked="locked"
          :spec="spec"
        />
      </v-list>
    </v-navigation-drawer>

    <v-progress-linear indeterminate :active="loading || saving"></v-progress-linear>

    <v-row>
      <v-col>
        <SpecificationToolbar
          :loading="loading"
          :spec="spec"
          :messages="messages"
          :can-create-approval="canCreateApproval"
          :locked="locked"
          @update="fetchData"
          @click:update="fetchData"
          @click:show-messages="showMessages = true"
          @approval:create="getApprovals(); fetchData();"
        />
      </v-col>
    </v-row>

    <router-view
      v-if="spec"
      :approvals="approvals"
      :spec="spec"
      :locked="locked"
      :canCreateApproval="canCreateApproval"
      :saving="saving"
      @section:delete="fetchData"
      @section:update="fetchData"
      @group:edit="fetchData"
    />

    <SpecificationMessages :spec="spec" :show="showMessages" :messages="messages" @click:close="showMessages = false" />
  </div>
</template>

<script>
import SpecificationSectionCreate from '@/components/specification/SpecificationSectionCreate.vue';
import SpecificationSectionGroupCreate from '@/components/specification/SpecificationSectionGroupCreate.vue';
import SpecificationToolbar from '@/components/specification/SpecificationToolbar.vue';
import SpecificationMessages from '@/components/specification/SpecificationMessages.vue';

export default {
  name: 'Specification',
  components: {
    SpecificationSectionCreate,
    SpecificationSectionGroupCreate,
    SpecificationToolbar,
    SpecificationMessages
  },
  data() {
    return {
      approvals: [],
      creating: false,
      loading: false,
      loadingApprovals: false,
      messages: [],
      saving: false,
      showMessages: false,
      spec: null
    }
  },
  computed: {
    relationRoute() {
      const { relationId, pathMatch } = this.$route.params;

      return `${pathMatch}/${relationId}`;
    },
    specBaseRoute() {
      const { relationId, specId, pathMatch } = this.$route.params;

      return `${pathMatch}/${relationId}/specification/${specId}`;
    },
    locked() {
      // If manually locked, it's locked
      if (this.spec.lockedOn) {
        return true;
      }

      else if (this.approvals.length) {
        const latest = this.approvals[0];

        // Lock the spec if..
        // .. an approval has been approved
        if (this.approvals.some(x => x.approved)) {
          return true;
        }

        // .. the latest approval has been sent but not responded
        else if (latest.sentOn && !latest.respondedOn) {
          return true;
        }

        else {
          return false;
        }
      }

      else {
        return false;
      }
    },
    canCreateApproval() {
      // User can create a new approval when the spec is not locked and the latest approval has been rejected
      return !this.approvals.length || (!this.locked && this.approvals[0]?.approved === false);
    }
  },
  async mounted() {
    this.fetchData();
    this.getApprovals();
    
    this.messages = await this.$store.dispatch('messages/getMessages', [this.$route.params.specId]);
  },
  methods: {
    async fetchData() {
      const { specId } = this.$route.params;

      try {
        this.loading = true;

        const response = await this.$AuthService.api.get(`specification/${specId}`);

        if (!response.data.success || !response.data.model) {
          throw response.data.message ?? 'Error getting specification';
        }

        this.spec = response.data.model;
        this.$store.commit('specification/setCurrentSpecification', response.data.model);
      } catch (error) {
        this.$notifier.showError({});
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getApprovals() {
      this.loadingApprovals = true;
      const { specId } = this.$route.params;

      try {
        const response = await this.$AuthService.api.get(`approval/component`, {
          params: {
            relationKind: 'specification',
            relationId: specId
          }
        });

        const approvals = response.data?.data?.approvals;
        const sorted = approvals.sort((a, b) => {
          return new Date(b.createdOn) - new Date(a.createdOn);
        });

        this.approvals = sorted;
      } catch (error) {
        this.$notifier.showError({});
        console.error(error);
      } finally {
        this.loadingApprovals = false;
      }
    },

    async createSection() {
      try {
        this.creating = true;

        this.newSection.specification_Id = this.spec.id;
        this.newSection.mainNumber = this.spec.sections.length ? (Math.max(...this.spec.sections.map(section => section.mainNumber))) + 1 : 1;
        this.newSection.subNumber = 0;

        const response = await this.$AuthService.api.post('specification/section', this.newSection);

        if (!response.data.success) throw 'Error creating section';

        this.spec.sections.push(response.data.model);
        this.newSectionMenu = false;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.creating = false;
      }
    }
  }
}
</script>
