<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>
        Specifikation - {{ spec.title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu v-model="editTitle" :close-on-content-click="false" min-width="400" left offset-y>
        <template #activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" text small>
            Ret navn
            <v-icon right> mdi-pencil-outline </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-text-field v-model="titleInput" autofocus label="Navn" clearable />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="editTitle = false">
              Annuller
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="saving" @click="update">
              Gem
              <v-icon right> mdi-content-save-outline </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-menu v-model="deleteMenu" left bottom :close-on-content-click="false">
        <template #activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" :disabled="$attrs.locked" text small>
            Slet
            <v-icon right> mdi-delete-outline </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title> Er du sikker? </v-card-title>
          <v-card-text class="warning--text">
            OBS! Alle aftaler forbundet til denne specifikation vil også blive slettet permanent.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="deleteMenu = false"> Nej, gå tilbage </v-btn>
            <v-btn :loading="deleting" color="error" @click="deleteSpecification">
              Ja, slet specifikationen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-btn
        v-if="!spec.lockedOn"
        :loading="locking"
        :disabled="!!spec.lockedOn"
        text
        small
        @click="lock"
      >
        Lås
        <v-icon right> mdi-lock-outline </v-icon>
      </v-btn>

      <v-btn v-else :loading="locking" text small @click="unlock">
        Lås op
        <v-icon right> mdi-lock-open-outline </v-icon>
      </v-btn>

      <v-btn :loading="loading" text small @click="$emit('click:update')">
        Opdater
        <v-icon right> mdi-sync </v-icon>
      </v-btn>

      <v-btn :loading="creating" :disabled="!canCreateApproval" text small @click="createApproval">
        Opret aftale
        <v-icon right> mdi-send-outline </v-icon>
      </v-btn>

      <v-badge :content="messageCount" :value="messageCount" overlap>
        <v-btn text small @click="$emit('click:show-messages')">
          <v-icon small> mdi-message-outline </v-icon>
        </v-btn>
      </v-badge>
    </v-toolbar>
  </div>
</template>

<script>
import SpecificationMessages from './SpecificationMessages.vue';

export default {
  name: 'SpecificationToolbar',
  components: {
    SpecificationMessages
  },
  props: {
    canCreateApproval: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    spec: {
      type: Object,
      required: true
    },
    messages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      creating: false,
      deleting: false,
      deleteMenu: false,
      editTitle: false,
      locking: false,
      saving: false,
      titleInput: this.spec.title
    }
  },
  computed: {
    messageCount() {
      return this.messages.filter(x => x.type === 'Message').length
    }
  },
  methods: {
    createApproval: async function() {
      this.creating = true;

      try {
        const approvalTypes = await this.$AuthService.api.get(`approval/types/specification`);
        const type = approvalTypes.data.find(type => type.text === 'Materialebestilling');

        const response = await this.$AuthService.api.post(`approval/component/create/${this.spec.id}/${type.value}`);

        if (!response.data.success || typeof response.data.message !== 'string') throw 'Error creating approval';

        this.spec.approvalId = response.message;
        this.$notifier.showSuccess({ message: 'Materialebestilling oprettet' });
        this.$emit('approval:create');
      } catch (error) {
        this.$store.dispatch('logError', { level: 'critical', transaction: 'ApprovalCreate', error })
        this.$notifier.showError({ message: this.$t('errors.createApproval') });
      } finally {
        this.creating = false;
      }
    },

    deleteSpecification: async function() {
      try {
        this.deleting = true;

        const response = await this.$AuthService.api.delete(`specification/${this.$route.params.specId}`);

        if (!response.data.success) throw 'Error deleting specification';

        this.$notifier.showSuccess({ message: 'Specifikationen blev slettet' });
        this.deleteMenu = false;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Der skete en fejl. Specifikationen blev ikke slettet.' });
      } finally {
        this.deleting = false;
      }
    },

    lock: async function() {
      this.locking = true

      this.$store.dispatch('specification/lock', this.$route.params.specId)
        .then(() => this.$emit('update'))
        .finally(() => this.locking = false);
    },

    unlock: async function() {
      this.locking = true;

      this.$store.dispatch('specification/unlock', this.$route.params.specId)
      .then(() => this.$emit('update'))
      .finally(() => this.locking = false)
    },

    update: async function() {
      const data = {
        title: this.titleInput,
        lockedOn: this.spec.lockedOn
      };

      try {
        this.saving = true;

        const response = await this.$AuthService.api.patch(`specification/${this.$route.params.specId}`, data);

        if (!response.data.success) throw 'Error updating spec';

        this.$notifier.showSuccess({ message: 'Ændringerne blev gemt' });
        this.editTitle = false;
        this.$emit('update');
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
        throw error;
      } finally {
        this.saving = false;
      }
    },
  }
}
</script>