<template>
  <v-menu
    v-model="newSectionMenu"
    offset-x
    right
    nudge-left="10"
    :close-on-content-click="false"
    min-width="400"
  >
    <template #activator="{ attrs, on }">
      <v-list-item v-bind="attrs" v-on="on" :disabled="locked" class="pl-6">
        <v-list-item-avatar>
          <v-icon> mdi-plus </v-icon>
        </v-list-item-avatar>
        <v-list-item-title> Ny sektion </v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            {{ group.name }}
            <v-text-field v-model="newSection.title" autofocus label="Titel" @keyup.enter="createSection" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="createSection">
          Opret
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'SpecificationSectionCreate',
  props: {
    group: {
      type: Object,
      default: null
    },
    locked: {
      type: Boolean,
      required: true
    },
    spec: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newSection: {
        title: ''
      },
      newSectionMenu: false
    }
  },
  methods: {
    async createSection() {
      try {
        this.creating = true;

        this.newSection.specification_Id = this.spec.id;
        this.newSection.specificationGroup_id = this.group?.id;

        const response = await this.$AuthService.api.post('specification/section', this.newSection);

        if (!response.data.success) throw 'Error creating section';

        this.group.sections.push(response.data.model);
        this.newSectionMenu = false;
        this.newSection = { title: '' };
        this.$notifier.showSuccess({ message: 'Sektion oprettet' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.creating = false;
      }
    }
  }
}
</script>