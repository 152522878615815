<template>
  <v-menu
    v-model="newGroupMenu"
    offset-x
    right
    nudge-left="10"
    :close-on-content-click="false"
    min-width="400"
  >
    <template #activator="{ attrs, on }">
      <v-list-item v-bind="attrs" v-on="on" :disabled="locked">
        <v-list-item-avatar>
          <v-icon> mdi-folder-plus-outline </v-icon>
        </v-list-item-avatar>
        <v-list-item-title> Ny mappe </v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field v-model="newGroup.name" autofocus label="Navn" hint='Pst! Tryk "Enter" for at oprette' @keyup.enter="createSectionGroup" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="createSectionGroup">
          Opret
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'SpecificationSectionGroupCreate',
  props: {
    locked: {
      type: Boolean,
      required: true
    },
    spec: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newGroup: {
        name: ''
      },
      newGroupMenu: false
    }
  },
  methods: {
    async createSectionGroup() {
      try {
        this.creating = true;

        this.newGroup.specification_Id = this.spec.id;

        const response = await this.$AuthService.api.post('specification/section-group', this.newGroup);

        if (!response.data.success) throw 'Error creating section';

        this.newGroupMenu = false;
        this.$notifier.showSuccess({ message: 'Mappe oprettet' });
        this.spec.groups.push(response.data.model);
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.creating = false;
      }
    }
  }
}
</script>